<template>
	<div v-if="files.length > 0" class="mt-4" :class="isLightboxThumbsOpen ? 'fixed' : ''">
		<!--Snapshot controls-->

		<!--Documents-->
		<v-card-text class="pt-0">
			<v-row style="margin: 0; width: 100%">
				<div class="d-flex flex-column full-width">
					<div
						@click="() => selectFile(file)"
						v-for="file in files"
						:key="fileKey(file)"
						:class="documentClass(file)">
						<template>
							<div class="d-flex justify-space-between py-2">
								<div class="d-flex">
									<v-icon color="#ff460b" size="40">icon-file-blank-stroke</v-icon>
									<div>
										<div>
											{{ file.title }}
										</div>
										<div @click="openFileViewer(file.id)">
											<div>
												<div class="d-flex">
													<div class="file-name">
														.{{ file.extension }} |
														{{ getFormattedDate(file.createdAt) }}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div v-if="!isMobileView" class="actions">
									<!--Download-->
									<v-btn
										v-if="!isViewOnly"
										fab
										color="transparent"
										small
										elevation="0"
										@click="() => downloadUrl(file.src)">
										<v-icon class="mr-0">icon-download</v-icon>
									</v-btn>

									<!--Delete-->
									<v-btn
										v-if="!isViewOnly"
										fab
										color="transparent"
										small
										elevation="0"
										@click="() => deleteSelected(file.id)">
										<v-icon class="mr-0" color="red">icon-trash-full-stroke</v-icon>
									</v-btn>
								</div>
							</div>
							<v-divider v-if="!isMobileView"></v-divider>
						</template>
					</div>
				</div>
			</v-row>
		</v-card-text>
	</div>
</template>

<script>
	import api from "../../api";
	import timezones from "@/helpers/timezones";

	export default {
		props: {
			isViewOnly: Boolean,
			fileList: Array,
			isMobileView: Boolean,
			bulkSelectFiles: Boolean,
			selectedFileIds: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				files: [],
				loadingFiles: true,
				isLightboxThumbsOpen: false,
			};
		},
		methods: {
			async setFiles() {
				this.files = this.fileList;
				this.loadingFiles = false;
			},
			fileKey(file) {
				return file.id;
			},
			getFormattedDate(date) {
				return timezones.formatDate(date, "MMM D, YYYY");
			},
			openFileViewer(fileId) {
				this.$root.$emit("open-file-viewer", fileId);
			},
			isFileSelected(fileId) {
				return this.selectedFileIds.includes(fileId);
			},
			selectFile(file) {
				if (this.bulkSelectFiles) {
					this.$emit("toggle-file-selection", file.id);
				}
			},
			downloadUrl(url) {
				const link = document.createElement("a");
				link.href = url;
				link.download = url.substring(url.lastIndexOf("/") + 1);
				link.click();
			},
			async deleteSelected(id) {
				if (await this.$root.$dangerzone.open("Delete Document", `This will delete the document`)) {
					await api.deleteFile(id);
					const index = this.files.findIndex((s) => s.id == id);
					this.files.splice(index, 1);
				}
			},
			documentClass(file) {
				let docClass = "";
				if (this.selectedFileIds.includes(file.id)) {
					docClass += "selected-document";
				}
				if (this.isMobileView) {
					docClass += " background-set";
				}
				return docClass;
			},
		},
		mounted() {
			this.setFiles();
		},
		watch: {
			fileList: {
				handler() {
					this.setFiles();
				},
				deep: true,
			},
		},
	};
</script>

<style scoped>
	.full-width {
		width: 100%;
	}

	.actions {
		display: flex;
		align-items: center;
	}

	.background-set {
		background-color: #f5f5f5;
		margin-top: 10px;
		border-radius: 10px;
	}

	.selected-document {
		border: 1px solid blue;
	}

	.v-card__text {
		padding: 0 !important;
	}
</style>
