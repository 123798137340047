<template>
	<v-card>
		<div>
			<div
				class="d-flex justify-space-between"
				v-if="!(artifactCreatedDateMappingsMap && Object.keys(artifactCreatedDateMappingsMap).length)">
				No {{ getArtifactName(artifactType) }}
				<v-btn size="x-small" class="close-button" color="transparent" elevation="0" @click="closeModal"
					><v-icon>icon-close-medium</v-icon></v-btn
				>
			</div>
			<div v-if="artifactCreatedDateMappingsMap && Object.keys(artifactCreatedDateMappingsMap).length">
				<div class="d-flex justify-space-between heading">
					{{ getArtifactName(artifactType) }}
					<v-btn size="x-small" class="close-button" color="transparent" elevation="0" @click="closeModal"
						><v-icon>icon-close-medium</v-icon></v-btn
					>
				</div>
				<div class="d-flex justify-space-between action-buttons">
					<div class="d-flex justify-space-center">
						<v-btn class="white--text view-button" color="#BCAAA4" @click="toggleBulkSelect" elevation="0"
							>{{ bulkSelect ? `Back` : "Bulk Select" }}
						</v-btn>
						<v-btn
							v-if="bulkSelect"
							class="white--text view-button"
							color="#BCAAA4"
							@click="selectAll"
							elevation="0"
							>{{ artifacts.length == bulkSelectedIds.length ? "Deselect All" : "Select All" }}</v-btn
						>
					</div>
					<div v-if="bulkSelect" class="d-flex justify-space-center">
						<v-btn elevation="0" icon @click="downloadArtifacts">
							<v-icon size="20" color="black">icon-download</v-icon>
						</v-btn>
						<v-btn elevation="0" v-if="!isViewOnly" icon @click="deleteArtifacts">
							<v-icon size="20" color="red">icon-trash-stroke</v-icon>
						</v-btn>
					</div>
				</div>
				<div v-for="(artifacts, i) in artifactCreatedDateMappingsMap" :key="i" class="datemapping">
					<div class="date">{{ i }}</div>
					<DocumentView
						v-if="artifactType == 'files'"
						:file-list="artifacts"
						:is-mobile-view="true"
						:is-view-only="isViewOnly"
						:selected-file-ids="bulkSelectedIds"
						:bulk-select-files="bulkSelect"
						@toggle-file-selection="toggleFileSelection" />
					<v-list-item-group v-else class="d-flex w-100">
						<template>
							<v-container fluid class="container">
								<v-row>
									<v-col
										v-for="(artifact, index) in artifacts"
										:key="artifact.id"
										class="column">
										<v-list-item>
											<div
												class="video-player"
												@click="bulkSelect ? updateBulkSelect(artifact.id) : null">
												<div class="w-100" v-if="artifactType == 'recordings'">
													<VideoPlayer
														:disable-playback="bulkSelect"
														:video-id="videoRecordingId(artifact.id)"
														:recording-id="artifact.id"
														:source="artifact.recordingUrl"
														:can-snapshot="true"
														:filename="videoFileName(artifact)"
														@take-snapshot="() => createSnapshot(artifact.id)" />
												</div>
												<div class="" v-else-if="artifactType == 'snapshots'">
													<v-img
														@click="openLightBox(artifact.id)"
														:src="artifact.src"
														:class="
															bulkSelectedIds.includes(artifact.id)
																? 'artifact-item'
																: ''
														"
														class=""
														height="90px"
														max-height="75px"
														max-width="110px"
														:lazy-src="artifact.src"
														crossorigin="anonymous">
														<template v-slot:placeholder>
															<v-row
																class="fill-height ma-0"
																align="center"
																justify="center">
																<v-progress-circular
																	indeterminate
																	color="grey lighten-5"></v-progress-circular>
															</v-row>
														</template>
													</v-img>
												</div>
												<div v-else class="file-icon">
													<div class="document">
														<div class="document-edge"></div>
														<div class="document-content">
															<div class="file-name">
																{{ artifact.extension.toUpperCase() }}
															</div>
														</div>
													</div>
													<div class="file-type">
														{{ artifact.title }}
													</div>
												</div>
											</div>
										</v-list-item>
									</v-col>
								</v-row>
							</v-container>
						</template>
					</v-list-item-group>
				</div>
				<LightBox
					ref="lightBox"
					:snapshots="snapshots"
					:open-light-box="openLightBox"
					:show-gallery="false"
					@update:isLightboxThumbsOpen="toggleThumbnails" />
			</div>
		</div>
	</v-card>
</template>
<script>
	import moment from "moment-timezone";
	import timezones from "@/helpers/timezones";
	import { ArtifactType } from "@/enums/ArtifactType";
	import LightBox from "./LightBox.vue";
	import DocumentView from "./artifacts/DocumentView.vue";

	export default {
		components: {
			LightBox,
			DocumentView,
		},
		props: {
			recordings: Array,
			snapshots: Array,
			files: Array,
			projectId: String,
			createSnapshot: Function,
			artifactType: String,
			isViewOnly: Boolean,
		},

		data() {
			return {
				bulkSelect: false,
				bulkSelectedIds: [],
			};
		},
		async mounted() {},
		methods: {
			toggleThumbnails(val) {
				this.isLightboxThumbsOpen = !!val;
			},
			toggleFileSelection(fileId) {
				if (this.bulkSelectedIds.includes(fileId)) {
					this.bulkSelectedIds = this.bulkSelectedIds.filter((id) => id !== fileId);
				} else {
					this.bulkSelectedIds.push(fileId);
				}
			},
			downloadUrl(url) {
				const link = document.createElement("a");
				link.href = url;
				link.download = url.substring(url.lastIndexOf("/") + 1);
				link.click();
			},
			selectAll() {
				const artifacts = this.getArtifacts();
				if (this.bulkSelectedIds.length == artifacts.length) {
					this.bulkSelectedIds = [];
				} else {
					this.bulkSelectedIds = [];
					this.bulkSelectedIds.push(...artifacts.map((s) => s.id));
				}
			},
			downloadArtifacts() {
				const artifacts = this.getArtifacts();
				const artifactUrls = artifacts
					.filter((artifact) => this.bulkSelectedIds.includes(artifact.id))
					.map((s) => s.src);
				for (let i = 0; i < artifactUrls.length; i++) {
					setTimeout(() => {
						this.downloadArtifactUrl(artifactUrls[i]);
					}, i * 1000); // might be able to play around with this time?
				}
			},
			openLightBox(id) {
				if (this.bulkSelect) return;
				this.$refs.lightBox.openLightBox(id);
			},
			getArtifacts() {
				if (this.artifactType == ArtifactType.Recordings) {
					return this.recordings.map((recording) => ({
						...recording,
						src: recording.recordingUrl,
					}));
				} else if (this.artifactType == ArtifactType.Snapshots) {
					return this.snapshots;
				} else if (this.artifactType == ArtifactType.Files) {
					return this.files;
				}
			},
			downloadArtifactUrl(url) {
				const link = document.createElement("a");
				link.href = url;
				link.download = url.substring(url.lastIndexOf("/") + 1);
				link.click();
			},
			deleteArtifacts() {
				this.$emit("delete", this.artifactType, this.bulkSelectedIds);
				this.bulkSelectedIds = [];
			},
			updateBulkSelect(id) {
				if (this.bulkSelectedIds.includes(id)) {
					this.bulkSelectedIds = this.bulkSelectedIds.filter((i) => i !== id);
				} else {
					this.bulkSelectedIds.push(id);
				}
			},
			toggleBulkSelect() {
				this.bulkSelect = !this.bulkSelect;
				if (!this.bulkSelect) {
					this.bulkSelectedIds = [];
				}
			},
			videoFileName(recording) {
				return `recording.${this.projectId}.${recording.id}.mp4`;
			},
			videoRecordingId(recordingId) {
				return `video-${recordingId}`;
			},
			formatRecordingDate(recording) {
				return moment(recording.recordingStart).fromNow();
			},
			closeModal() {
				this.$emit("close");
			},
			closeGallery() {
				this.$refs.lightBox.hideThumbnailTray();
			},
			getArtifactName(artifactType) {
				if (artifactType == ArtifactType.Recordings) {
					return "Videos";
				} else if (artifactType == ArtifactType.Snapshots) {
					return "Photos";
				} else if (artifactType == ArtifactType.Files) {
					return "Documents";
				}
				return "";
			},
		},
		computed: {
			artifacts() {
				if (this.artifactType == ArtifactType.Recordings) {
					return this.recordings;
				} else if (this.artifactType == ArtifactType.Snapshots) {
					return this.snapshots;
				} else if (this.artifactType == ArtifactType.Files) {
					return this.files;
				}
				return [];
			},
			artifactCreatedDateMappingsMap() {
				const mappings = {};
				if (this.artifactType == ArtifactType.Recordings) {
					this.recordings.forEach((recording) => {
						const createdDate = timezones.formatDate(recording.recordingStart, "MMM DD, YYYY");
						if (mappings[createdDate] === undefined) {
							mappings[createdDate] = [];
						}
						mappings[createdDate].push(recording);
					});
					return mappings;
				} else if (this.artifactType == ArtifactType.Snapshots) {
					this.snapshots.forEach((recording) => {
						const createdDate = timezones.formatDate(recording.createdAt, "MMM DD, YYYY");
						if (mappings[createdDate] === undefined) {
							mappings[createdDate] = [];
						}
						mappings[createdDate].push(recording);
					});
					return mappings;
				} else if (this.artifactType == ArtifactType.Files) {
					this.files.forEach((recording) => {
						const createdDate = timezones.formatDate(recording.createdAt, "MMM DD, YYYY");
						if (mappings[createdDate] === undefined) {
							mappings[createdDate] = [];
						}
						mappings[createdDate].push(recording);
					});
					return mappings;
				}
				return [];
			},
		},
	};
</script>
<style scoped>
	.container {
		padding: 0px !important;
		height: 100%;
	}
	.heading {
		font-size: 24px;
		font-weight: 600;
		margin: 4px;
	}
	.date {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 13px;
		margin-top: 13px;
	}
	.video-player {
		margin-bottom: 10px;
		height: 70px;
		width: 120px;
	}

	.column {
		padding-left: 0.5rem;
		padding-bottom: 0rem;
		padding-top: 0rem;
	}

	.close-button {
		padding-left: 0px !important;
		padding-right: 0px !important;
		max-width: 24px !important;
		min-width: 24px !important;
	}

	.view-button {
		height: 22px !important;
		width: 75px !important;
		font-size: 12px !important;
		border-radius: 8px;
		margin-right: 4px;
		margin-top: 4px;
	}

	.action-buttons {
		margin-bottom: 7px;
		margin-top: 28px;
	}

	.overlay {
		position: relative;
		background-color: transparent;
		border-radius: 20px;
		z-index: 2000;
	}
	.bulk-selected {
		border-radius: 10px !important;
		border-width: 50px !important;
		border-color: blue !important;
		background-color: blue;
	}

	.artifact-item {
		padding: 0;
		border: 2px solid blue;
		margin: 8px;
	}	
	.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before{
		opacity: 0;
		transition: 0ms;
	}

	.v-list-item {
		padding: 0;
	}
	.row {
		padding-right: 10px;
	}

	.document {
		width: 90px;
		height: 60px;
		background-color: #f0f0f0;
		border: 2px solid #ccc;
		border-radius: 3px;
		margin-right: 2px;
	}

	.project-readonly {
		padding: 16px !important;
	}

	.document-edge {
		width: 0px;
		height: 0px;
		border-top: 20px solid #ccc;
		border-right: 20px solid transparent;
		top: -2px;
		right: -2px;
	}

	.document-content {
		width: 40px !important;
		text-align: center;
	}

	.file-type {
		font-size: 10px;
		margin-bottom: 10px;
		max-width: 120px;
		font-weight: bold;
		color: #666;
		white-space: wrap;
		line-height: 1.375;
		word-break: break-word;
	}

	.file-icon {
		margin-bottom: 200px;
	}

	.file-name {
		font-size: 12px;
	}
	.datemapping {
		height: inherit;
	}
	.row {
		margin-left: 0px;
		margin-right: 0px;
	}

	.v-card {
		padding: 10px;
		height: 100%;
		border-radius: 16px;
		width: 100%;
	}
</style>
